.display_flex {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
}

.flex_align_center {
    align-items: center;
    -webkit-align-items: center;
}

.justify_center {
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
}

.space_between {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
}

.change_password_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

#button .button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

#button,
#button .dx-button-content {
    padding: 0;
}

.width_50_percent{
    width: 50%;
}

.width_100_percent{
    width: 100%;
}