.login_main {
    background-color: #e5f6ff;
}

.txt_blue_color {
    color: #10aee5;
}

.display_flex {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
}

.flex_align_center {
    align-items: center;
    -webkit-align-items: center;
}

.justify_center {
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
}

.space_between {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
}

.login_left_side {
    width: 33%;
}

.login_right_side {
    width: 67%;
}

.height_100p {
    height: 100vh;
}

.blue_color_fifth_bg {
    background-color: #22304d;
}

.padding_24 {
    padding: 24px;
}

.margin_top_48 {
    margin-top: 48px;
}

.margin_bottom_48 {
    margin-bottom: 48px;
}

.margin_top_36 {
    margin-top: 36px;
}

.margin_bottom_36 {
    margin-bottom: 36px;
}

.margin_24 {
    margin: 24px;
}

.margin_top_24 {
    margin-top: 24px;
}

.margin_bottom_24 {
    margin-bottom: 24px;
}

.margin_top_16 {
    margin-top: 16px;
}

.margin_bottom_16 {
    margin-bottom: 16px;
}

.margin_top_19 {
    margin-top: 19px;
}

.margin_bottom_19 {
    margin-bottom: 19px;
}

.hard_blue_color {
    color: #22304d;
    font-family: "muller-bold";
}

.margin_bottom_4 {
    margin-bottom: 4px;
}

.font_24 {
    font-size: 24px;
}

.font_12 {
    font-size: 12px;
}

.border_1_green {
    border: 1px solid #99ddff;
}

.width_360 {
    width: 360px;
}

.full_logo_width {
    width: 192px;
}

.width_100 {
    width: 100%;
}

.width_48 {
    width: 48px;
}

.height_40 {
    height: 40px;
}

.input_style {
    border: 2px solid #99ddff !important;
    border-radius: 8px !important;
    background-color: #ffffff !important;
}

.login_submit_button {
    width: 147px;
    height: 50px;
    border-radius: 12px;
    background-color: #009edb;
    color: #ffffff;
    border: 1px solid #009edb;
    padding-top: 3px;
}

.form_section {
    margin-left: 25%;
    margin-bottom: 56px;
}
.flex_wrap:lang(ar){
    flex-wrap: wrap;
    direction: rtl;
}
.form_section:lang(ar){
    margin-right: 30%;
    margin-left: 0;
    margin-bottom: 56px;
}


.logo {
    margin-bottom: 56px;
}

.input_style:focus-visible {
    outline: none;
    border: 2px solid #009edb;
}

.position_relative {
    position: relative;
}

.powered_by {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid rgba(0, 158, 219, 0.48);
    color: white;
    font-family: "muller-Light";
}

.input_custom_form_style {
    .form-label {
        font-family: "muller-Medium";
        font-size: 12px;
        color: #22304d;
        margin-bottom: 4px;
    }
}

.form-control:focus {
    box-shadow: none;
}

.signin_btn {
    font-family: "muller-Medium";
}
.login_button_name {
    color: #ffffff;
    position: relative;
    border: unset;
}
.login_chkbox {
    justify-content: center;
    text-align: right;
    padding-top: 10px;
    font-size: 12px;
    font-family: "muller-Medium";
}
.login_checkbox {
    font-size: 12px;
    font-family: "muller-Medium";
}
.flex_wrap {
    flex-wrap: wrap;
}

.login_copyright {
    font-size: 12px;
    padding-left: 28px;
    font-family: "muller-Light";
}
.forgot-password {
    font-size: 12px;
    font-family: "muller-Medium";
}

.chkbox_btn_align {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
}

.spinner {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 12px;
    top: 10px;
}
.position_relative {
    position: relative;
}
.form-check-label {
    margin-bottom: 0;
    margin-top: 3px;
}
.login_alert_msg {
    position: absolute;
    top: 0px;
}

// ===================================== login-page ==============================//
@media (max-width: 767px) {
    .height_100p {
        height: 100%;
        // height: 100vh;
    }
    .flex_wrap:lang(ar){
        flex-wrap: wrap-reverse;
        direction: rtl;
    }
    .login_right_side {
        width: 100%;
        flex-wrap: wrap-reverse;
        height: calc(100vh - 84px);
    }
    .login_left_side {
        width: 100%;
        flex-wrap: wrap-reverse;
        height: 84px;
    }
    .form_section {
        margin: 0px auto;
    }
    .form_section:lang(ar) {
        direction: rtl;
        text-align: right;
    }
    .width_360 {
        width: 300px;
    }
    .logo {
        display: none;
    }
    .flex_wrap {
        flex-wrap: wrap-reverse;
    }
    .powered_by {
        text-align: center;
        border-top: none;
    }

    .powered_by:lang(ar){
        direction: rtl;
        text-align: center;
    }
    .login_copyright {
        margin-top: 4px;
        text-align: center;
        display: block;
        padding-left: unset;
    }
    .powered_by.font_12.muller_light:lang(ar)
    {
        direction: rtl;
    }

    .form_section:lang(ar){
        margin-right: 24px;
        margin-left: 0;
        margin-bottom: 56px;
    }
}
