// ===================================== purchase-page ==============================//
.signpurchase {
    position: absolute;
    bottom: 44px;
}

.free_signpurchase {
    bottom: 72px !important;
}

.signpurchase:lang(ar) {
    position: absolute;
    bottom: 44px;
    margin-left: 50px;
    margin-right: 0px;
}

.purchase-page {
    height: 100vh; /* For 100% screen height */
    width: 100vw; /* For 100% screen width */
    .purchase-page-div {
        height: 100%;
        border-top: 8px solid #10aee5;
        background: rgba($color: #d4e8ff, $alpha: 0.36) 0% 0% no-repeat padding-box;
        position: relative;
        .purchase-total {
            position: absolute;
            width: 100%;
            max-width: 800px;
            height: 380px;
            left: 50%;
            top: 50%;
            margin-top: -220px;
            margin-left: -400px;
        }
        .contents {
            padding-top: 10px;
            margin-bottom: 17px;
            .purchase_heading_section {
                margin-bottom: 22px;
                .profile {
                    font-size: 24px;
                    color: #1a2e54;
                }
                .purchase_progress {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .logo {
                padding-bottom: 36px;
            }
            p {
                margin-top: 22px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0px;
                color: #1a2e54;
                opacity: 1;
                margin-bottom: 0px;
                padding-bottom: 67px;
                width: 272px;
            }
            form {
                .form-group {
                    label {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        color: #444444;
                        opacity: 1;
                        margin-left: 0px;
                    }
                    .form-control {
                        margin-left: 0px;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        border: 2px solid #bbbbbb;
                        border-radius: 8px;
                        height: 50px;
                        width: 100%;
                        padding: 12px 18px 15px 16px;
                        color: #0f0f0f;
                        &::placeholder {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0px;
                            color: #bbbbbb;
                            opacity: 1;
                        }
                        &:focus {
                            box-shadow: unset;
                            outline: 0;
                        }
                    }
                }
            }
        }
    }
}

.purchase-page:lang(ar) {
    height: 100vh; /* For 100% screen height */
    width: 100vw; /* For 100% screen width */
    direction: rtl;
    text-align: right;
    .purchase-page-div:lang(ar) {
        height: 100%;
        border-top: 8px solid #10aee5;
        background: rgba($color: #d4e8ff, $alpha: 0.36) 0% 0% no-repeat padding-box;
        position: relative;
        .purchase-total:lang(ar) {
            position: absolute;
            width: 100%;
            max-width: 800px;
            height: 380px;
            left: 50%;
            top: 50%;
            margin-top: -220px;
            margin-left: -400px;
        }
        .contents:lang(ar) {
            padding-top: 10px;
            margin-bottom: 17px;
            .purchase_heading_section:lang(ar) {
                margin-bottom: 22px;
                .profile:lang(ar) {
                    font-size: 24px;
                    color: #1a2e54;
                }
                .purchase_progress:lang(ar) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .logo:lang(ar) {
                padding-bottom: 36px;
            }
            p:lang(ar) {
                margin-top: 22px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0px;
                color: #1a2e54;
                opacity: 1;
                margin-bottom: 0px;
                padding-bottom: 67px;
                width: 272px;
            }
            form:lang(ar) {
                .form-group:lang(ar) {
                    label:lang(ar) {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        color: #444444;
                        opacity: 1;
                        margin-left: 0px;
                    }
                    .form-control:lang(ar) {
                        margin-left: 0px;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        border: 2px solid #bbbbbb;
                        border-radius: 8px;
                        height: 50px;
                        width: 100%;
                        padding: 12px 18px 15px 16px;
                        color: #0f0f0f;
                        &::placeholder:lang(ar) {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0px;
                            color: #bbbbbb;
                            opacity: 1;
                        }
                        &:focus:lang(ar) {
                            box-shadow: unset;
                            outline: 0;
                        }
                    }
                }
            }
        }
    }
}

.purchase-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    .purchase-footer-bottom {
        padding-top: 20px;
        padding-bottom: 19px;
        .align-content-center {
            display: flex;
            justify-content: center;
            color: #ffffff;
            .power {
                font-size: 10px;
            }
            .copyright {
                font-size: 12px;
                text-align: right;
            }
            .powered_logo {
                margin-bottom: 20px;
            }
        }
    }
}

.forgot-password {
    position: unset;
}

.chkbox_btn_align {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
}

.button_name {
    background: #0498e0 0% 0% no-repeat padding-box;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    padding: 15px 54px;
    position: relative;
    border: unset;
    &:focus {
        box-shadow: unset;
        border: unset;
    }
    &:hover {
        border: unset;
    }
    &.active {
        border: unset;
    }
}

.button_name:disabled {
    opacity: 0.5;
}

.chkbox {
    justify-content: center;
    display: flex;
}

.sign-bottom-section {
    position: relative;
}

.photo-size {
    width: 192px;
    height: 48px;
}

.purchasesignin {
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
}
.form-check-label:lang(ar) {
    padding-right: 20px;
}
/*---------------------------------------------------ipad-------Media Query----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .signincontent {
        margin-left: 48px;
    }
    .ipadlocation {
        margin-left: 48px;
    }
}
// ===================================== purchase-page ==============================//
@media (max-width: 767px) {
    .signpurchase {
        position: absolute;
        bottom: 0;
    }
    .photo-size {
        width: 192px;
        height: 48px;
        margin-top: 20px;
    }
    .free_signpurchase {
        bottom: 3px !important;
    }
    .purchase-page {
        height: auto;
        width: 100%;
        .purchase-page-div {
            height: auto;
            padding-bottom: 50px;
            .purchase-total {
                position: unset;
                width: unset;
                height: unset;
                left: unset;
                top: unset;
                margin-top: unset;
                margin-left: -15px;
            }
            .contents {
                padding-top: 0px;

                .logo {
                    padding-bottom: 20px;
                    padding-top: 50px;
                }
                p {
                    padding-bottom: 25px;
                }
                form {
                    .form-group {
                        .form-control {
                            width: 100%;
                        }
                    }
                    .form-check {
                        margin-bottom: 16px;
                    }
                }
                .profile {
                    font-size: 20px;
                    margin-top: 25px;
                    margin-bottom: 15px;
                }
                .chkbox_btn_align {
                    display: unset;
                    .chkbox {
                        display: unset;
                    }
                }
                .signin_btn {
                    text-align: center;
                }
                .forgot-password {
                    margin-top: 0px;
                    position: absolute;
                    top: 0;
                    right: 10px;
                }
            }
        }

        .purchase-footer {
            position: unset;
            bottom: unset;
            width: 100%;
            border-radius: unset;
            .align-content-center {
                justify-content: center;
                .power {
                    text-align: center;
                }
                .copyright {
                    text-align: center;
                }
            }
        }
    }
    .purchase-page:lang(ar) {
        .purchase-page-div:lang(ar) {
            .contents:lang(ar) {
                .forgot-password:lang(ar) {
                    right: unset;
                    left: 10px;
                }
            }
        }

        .purchase-footer {
            position: unset;
            bottom: unset;
            width: 100%;
            border-radius: unset;
            .align-content-center {
                justify-content: center;
                .power {
                    text-align: center;
                }
                .copyright {
                    text-align: center;
                }
            }
        }
    }
    .form-check-label:lang(ar) {
        padding-right: 20px;
    }
}
