.App {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.item_center {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

// table {
//     border: 1px solid black;
//     width: 90%;
// }

// th {
//     border: 1px solid black;
//     text-align: center;
//     padding: 5px;
// }

// td {
//     border: 1px solid black;
//     text-align: center;
//     padding: 7px;
// }
.margin_right_16 {
    margin-right: 16px;
}

.App .login_submit_button,
.logout_btn .login_submit_button {
    padding-top: 9px;
    text-decoration: none;
}
.App a {
    text-decoration: none;
}

.service_list {
    position: relative;
}
.logout_btn {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
}

.logout_btn a {
    text-decoration: none;
}

.cursor_pointer {
    cursor: pointer;
}

@media (max-width: 767px) {
    .item_center {
        height: auto;
        padding-top: 45vh;
        flex-wrap: wrap;
        & > div {
            margin-bottom: 24px;
        }
    }
}
