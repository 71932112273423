@font-face {
  font-family: "Muller";
  src: url("./font/MullerThin.ttf");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("./font/MullerUltraLight.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("./font/MullerLight.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("./font/MullerRegular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("./font/MullerMedium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("./font/MullerMediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Muller";
  src: url("./font/MullerBold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("./font/MullerExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("./font/MullerBlack.ttf");
  font-weight: 900;
  font-style: normal;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  font-family: "Muller";
  font-weight: normal;
  font-style: normal;
}