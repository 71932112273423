.email_component {
    font-family: "Roboto", sans-serif;
    .login_submit_button {
        padding-top: 8px;
    }
    .logout_btn {
        top: 15px;
    }
}

.display_flex {
    display: flex;
}

.space_between {
    justify-content: space-between;
}

.align_item_center {
    align-items: center;
}

.align_item_baseline {
    align-items: baseline;
}

.align_item_self_end {
    align-items: self-end;
}

.margin_bottom_48 {
    margin-bottom: 48px;
}

.margin_bottom_24 {
    margin-bottom: 24px;
}

.margin_right_8 {
    margin-right: 8px;
}

.margin_bottom_8 {
    margin-bottom: 8px;
}

.margin_right_16 {
    margin-right: 16px;
}

.cursor_pointer {
    cursor: pointer;
}

.font_16 {
    font-size: 16px !important;
}

.header {
    background: #f1f7ff 0% 0% no-repeat padding-box;
    border-top: 8px solid #009edb;
    height: 66px;
    .navbar-brand {
        margin-right: 44px;
    }
    .navbar {
        padding: 0px 24px;
    }
    .navbar-light {
        .navbar-nav {
            .nav-link {
                position: relative;
                padding: 17px 19px;
                text-align: center;
                text-align: left;
                font-family: "Roboto", sans-serif;
                font-size: 18px;
                line-height: 22px;
                font-weight: 700;
                letter-spacing: 0px;
                color: #22304d;
                opacity: 0.5;
                &.active,
                &:hover {
                    background: #ffffff;
                    opacity: 1;
                    &::before {
                        position: absolute;
                        z-index: 2;
                        content: "";
                        width: 100%;
                        height: 8px;
                        background: #22304d;
                        left: 0px;
                        right: 0px;
                        top: -9px;
                    }
                }
            }
        }
    }
}

.lp-body {
    padding: 16px;
}

.left_section {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    width: 58%;
    margin-right: 16px;
    padding: 48px;
    min-height: calc(100vh - 97px);
}

.right_section {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    width: 42%;
    padding: 48px;
    min-height: calc(100vh - 97px);
    &.width_100 {
        .exit_full_screen {
            display: block;
        }
        .full_screen {
            display: none;
        }
    }
    .exit_full_screen {
        display: none;
    }
    .full_screen {
        display: block;
    }
}

.width_100 {
    width: 100%;
}

.width_250 {
    width: 250px;
}

.preview_section {
    background: #ffffff;
    iframe {
        width: 100%;
        height: 74vh;
    }
    // min-height: calc(100vh - 285px);
    // height: 91%;
}

.btn_blue {
    background: #009edb 0% 0% no-repeat padding-box;
    border-radius: 4px;
    width: 140px;
    height: 40px;
    border: 1px solid #009edb;
    color: #ffffff;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}

.btn_ash {
    background: #dddddd 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 1px solid #dddddd;
    width: 140px;
    height: 40px;
    color: #444444;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}

.size_change {
    width: 80px;
    height: 30px;
}

h2,
h6 {
    margin-bottom: unset;
}

.form-label {
    color: #111111;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.upload_links {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}

.color_blue {
    color: #009edb;
}

.color_light_black {
    color: #111111;
}

.heading {
    text-align: left;
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #444444;
    font-family: "Roboto", sans-serif;
}

.file_type {
    opacity: 0;
    width: 120px;
    position: absolute;
    z-index: 2;
    right: 0px;
}

.position_relative {
    position: relative;
}

.form-control {
    border: 1px solid #dddddd;
    border-radius: 4px;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #dddddd;
    border: 1px solid #dddddd;
    border-radius: 4px;
    outline: 0;
    box-shadow: unset;
}

.form-select {
    border: 1px solid #dddddd;
    border-radius: 4px;
}

.form-select:focus {
    border-color: #dddddd;
    outline: 0;
    box-shadow: unset;
}

.select_width {
    width: 250px;
    z-index: 5;
}

.display_inline_flex {
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.center_align_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93vh;
}

.text_center {
    text-align: center;
}
